<!-- 编辑弹窗 -->
<template>
  <div>
    <a-modal
      :width="680"
      :visible="visible"
      :confirm-loading="loading"
      :title="isUpdate ? '修改租赁点' : '新建租赁点'"
      :body-style="{ paddingBottom: '8px' }"
      @update:visible="updateVisible"
      @ok="save"
    >
      <a-form
        ref="form"
        :model="form"
        :rules="rules"
        :label-col="{ md: { span: 7 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 17 }, sm: { span: 24 } }"
      >
        <a-row :gutter="16">
          <a-col :md="12" :sm="24" :xs="24">
            <a-form-item label="租赁点编码:" name="pointCode">
              <a-input
                v-model:value="form.pointCode"
                placeholder="请输入租赁点编码"
                allow-clear
              />
            </a-form-item>
            <a-form-item label="省市区:">
              <a-cascader
                v-model:value="this.form.fullCity"
                :options="cityData.cityData"
                placeholder="请选择租赁点省市区"
                popup-class-name="ele-pop-wrap-higher"
              />
            </a-form-item>
            <a-form-item label="租赁点类型:" name="pointType">
              <a-select
                v-model:value="form.pointType"
                placeholder="请选择租赁点类型"
                allow-clear
              >
                <a-select-option
                  v-for="item in pointTypeList"
                  :key="item.dictDataId"
                  :value="item.dictDataCode"
                >
                  {{ item.dictDataName }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="管理人:" name="manager">
              <a-input
                v-model:value="form.manager"
                placeholder="请输入管理人"
                allow-clear
              />
            </a-form-item>
            <a-form-item label="营运开始时间:" name="startTime">
              <a-time-picker
                use24-hours
                format="HH:mm"
                valueFormat="HH:mm:ss"
                v-model:value="form.startTime"
                v-model:open="startOpen"
              >
              </a-time-picker>
            </a-form-item>
            <a-form-item label="营运结束时间:" name="endTime">
              <a-time-picker
                use24-hours
                format="HH:mm"
                valueFormat="HH:mm:ss"
                v-model:value="form.endTime"
                v-model:open="endOpen"
              >
              </a-time-picker>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24" :xs="24">
            <a-form-item label="租赁点名称:" name="pointName">
              <a-input
                v-model:value="form.pointName"
                placeholder="请输入租赁点名称"
                allow-clear
              />
            </a-form-item>
            <a-form-item label="营运地址:" name="address">
              <a-input
                v-model:value="form.address"
                placeholder="请输入所在营运地址"
                allow-clear
              />
            </a-form-item>
            <a-form-item label="所属站点组:" name="groupName">
              <a-select
                v-model:value="form.groupId"
                placeholder="请选所属站点组"
                allow-clear
              >
                <a-select-option
                  v-for="item in groupList"
                  :key="item.groupId"
                  :value="item.groupId"
                >
                  {{ item.groupName }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="手机号:" name="phone">
              <a-input
                v-model:value="form.phone"
                placeholder="请输入管理人手机号"
                allow-clear
              />
            </a-form-item>
            <a-form-item label="运营商:" name="operator">
              <a-select
                v-model:value="form.operatorId"
                placeholder="请选择运营商"
                allow-clear
              >
                <a-select-option
                  v-for="item in operatorList"
                  :key="item.operatorId"
                  :value="item.operatorId"
                >
                  {{ item.operatorName }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="地图坐标:" name="coordinate">
              <a-button type="primary" @click="coordinate()">地图选点</a-button>
              <div>经度: {{ form.longitude }} 纬度: {{ form.latitude }}</div>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24" :xs="24">
            <a-form-item label="租赁点照片" name="pointPhoto">
              <a-upload
                list-type="picture-card"
                v-model:file-list="pointPhotoList"
                @preview="handlePreview"
                :customRequest="({ file }) => uploadFile(file, 'pointPhoto')"
                :remove="removeFile"
              >
                <div v-if="pointPhotoList.length < 1">
                  <plus-outlined />
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                :bodyStyle="{paddingTop:'50px'}"
                @cancel="previewVisible = false"
              >
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24" :xs="24">
            <a-form-item label="营业执照" name="businessLicense">
              <a-upload
                list-type="picture-card"
                v-model:file-list="businessLicenseList"
                @preview="handlePreview"
                :customRequest="
                  ({ file }) => uploadFile(file, 'businessLicense')
                "
                :remove="removeFile"
              >
                <div v-if="businessLicenseList.length < 1">
                  <plus-outlined />
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                :bodyStyle="{paddingTop:'50px'}"
                @cancel="previewVisible = false"
              >
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24" :xs="24">
            <a-form-item label="身份证(正面)" name="idcardPic">
              <a-upload
                list-type="picture-card"
                v-model:file-list="idcardPicList"
                @preview="handlePreview"
                :customRequest="({ file }) => uploadFile(file, 'idcardPic')"
                :remove="removeFile"
              >
                <div v-if="idcardPicList.length < 1">
                  <plus-outlined />
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                :bodyStyle="{paddingTop:'50px'}"
                @cancel="previewVisible = false"
              >
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24" :xs="24">
            <a-form-item label="身份证(反面)" name="idcardPic2">
              <a-upload
                list-type="picture-card"
                v-model:file-list="idcardPic2List"
                @preview="handlePreview"
                :customRequest="({ file }) => uploadFile(file, 'idcardPic2')"
                :remove="removeFile"
              >
                <div v-if="idcardPic2List.length < 1">
                  <plus-outlined />
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                @cancel="handleCancel"
                :bodyStyle="{paddingTop:'50px'}"
              >
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
    <a-modal
      :width="680"
      :visible="showPostion"
      title="地图选址"
      :body-style="{ paddingBottom: '8px' }"
      @cancel="cancelPosition"
      footer=""
    >
      <Map @done="setPosition" v-model:position="position" id="pointAddress" />
    </a-modal>
  </div>
</template>

<script>
import Map from '@/components/map/addressPicker'
import * as pointApi from '@/api/rnt/point'
import * as groupApi from '@/api/rnt/group'
import * as operatorApi from '@/api/rnt/operator'
import * as dictDataApi from '@/api/rnt/pointtype'
import regions from '@/utils/regions'
import setting from '@/config/setting'
import { PlusOutlined } from '@ant-design/icons-vue'
function getBase64(pointPhoto) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(pointPhoto)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
export default {
  name: 'pointEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: {
    PlusOutlined,
    Map
  },
  data() {
    return {
      startValue: null,
      startOpen: false,
      endOpen: false,
      // 表单数据
      pageData: {},
      // 省市区数据
      cityData: regions,
      headers: {},
      uploadUrl: setting.uploadUrl,
      // 表单数据
      form: Object.assign({}, this.data, {
        modulesArray: [],
        indeterminate: false,
        checkAll: false
      }),
      // 编辑弹窗表单验证规则
      rules: {
        pointName: [
          {
            required: true,
            message: '请输入租赁点名称',
            type: 'string',
            trigger: 'blur'
          }
        ],
        pointCode: [
          {
            required: true,
            message: '请输入租赁点编码',
            type: 'string',
            trigger: 'blur'
          }
        ]
      },
      position: {},
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      pointList: [],
      pointTypesList: [],
      operatorList: [],
      // 租赁点照片
      pointPhotoList: [],
      // 营业照
      businessLicenseList: [],
      // 身份证照（正面）
      idcardPicList: [],
      // 身份证照（反面）
      idcardPic2List: [],
      showPostion: false,
      previewVisible: false,
      previewImage: ''
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = this.data
        const fullCity = [
          this.form.province,
          this.form.city,
          this.form.district
        ]
        this.form.fullCity = fullCity
        this.pointPhotoList = []
        if (this.form.pointPhoto) {
          this.pointPhotoList[0] = {
            uid: '-4',
            name: 'image.png',
            status: 'done',
            url: this.form.pointPhoto
          }
        }
        if (this.form.businessLicense) {
          this.businessLicenseList[0] = {
            uid: '-3',
            name: 'image.png',
            status: 'done',
            url: this.form.businessLicense
          }
        }

        if (this.form.idcardPic) {
          this.idcardPicList[0] = {
            uid: '-2',
            name: 'image.png',
            status: 'done',
            url: this.form.idcardPic
          }
        }
        if (this.form.idcardPic2) {
          this.idcardPic2List[0] = {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: this.form.idcardPic2
          }
        }

        if (this.form.longitude) {
          this.position = {
            fullAddress: this.form.fullCity + this.form.address,
            longitude: this.form.longitude,
            latitude: this.form.latitude
          }
        } else {
          this.position = {}
        }
        this.isUpdate = true
      } else {
        this.form = {}
        this.position = {}
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  mounted() {
    // 省市区
    this.headers[setting.tokenHeaderName] = setting.getToken()
    this.queryPoint()
    this.queryGroup()
    this.queryOperator()
    this.queryPointType()
  },
  methods: {
    removeFile(file) {
      this.$http
        .delete('/file/remove', {
          path: file.url
        })
        .then((res) => {
          if (res.data.code === 0) {
            if (file.uid === '-3') {
              this.businessLicenseList = []
              this.form.businessLicense = ''
            }
            if (file.uid === '-2') {
              this.idcardPicList = []
              this.form.idcardPic = ''
            }
            if (file.uid === '-1') {
              this.idcardPic2List = []
              this.form.idcardPic2 = ''
            }
            if (file.uid === '-4') {
              this.pointPhotoList = []
              this.form.pointPhoto = ''
            }
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    /* 保存编辑 */
    save() {
      if (this.form.fullCity && this.form.fullCity.length === 3) {
        this.form.province = this.form.fullCity[0]
        this.form.city = this.form.fullCity[1]
        this.form.district = this.form.fullCity[2]
      }
      if (this.form.startTime && typeof this.form.startTime === 'object') {
        this.form.startTime = this.form.startTime.format('HH:mm:ss')
      }
      if (this.form.endTime && typeof this.form.endTime === 'object') {
        this.form.endTime = this.form.endTime.format('HH:mm:ss')
      }
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          pointApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.form = {}
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    created() {
      this.initData()
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    /* 查询租赁点列表 */
    queryPoint() {
      pointApi
        .all()
        .then((res) => {
          if (res.code === 0) {
            this.pointList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询租赁点类型 */
    queryPointType() {
      dictDataApi
        .pointType()
        .then((res) => {
          if (res.code === 0) {
            this.pointTypeList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询所属站点组 */
    queryGroup() {
      groupApi
        .all()
        .then((res) => {
          if (res.code === 0) {
            this.groupList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询运营商 */
    queryOperator() {
      operatorApi
        .all()
        .then((res) => {
          if (res.code === 0) {
            this.operatorList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    checkAllChange(e) {
      Object.assign(this.form, {
        modulesArray: e.target.checked ? this.moduleOptions : [],
        indeterminate: false
      })
    },
    uploadFile(file, name) {
      const formData = new FormData()
      formData.append('file', file)
      const hide = this.$message.loading('上传中..', 0)
      this.$http
        .post(this.uploadUrl, formData)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            if (name === 'pointPhoto') {
              this.pointPhotoList[0] = {
                uid: '-4',
                name: 'image.png',
                status: 'done',
                url: res.data.location
              }
            } else if (name === 'businessLicense') {
              this.businessLicenseList[0] = {
                uid: '-3',
                name: 'image.png',
                status: 'done',
                url: res.data.location
              }
            } else if (name === 'idcardPic') {
              this.idcardPicList[0] = {
                uid: '-2',
                name: 'image.png',
                status: 'done',
                url: res.data.location
              }
            } else if (name === 'idcardPic2') {
              this.idcardPic2List[0] = {
                uid: '-1',
                name: 'image.png',
                status: 'done',
                url: res.data.location
              }
            }
            this.form[name] = res.data.location
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
      return false
    },
    coordinate() {
      this.postion = this.form
      this.showPostion = true
    },
    setPosition(data) {
      this.form.longitude = data.longitude
      this.form.latitude = data.latitude
      this.showPostion = false
    },
    cancelPosition() {
      this.showPostion = false
    },
    handleClose(val) {
      if (val === 1) {
        this.startOpen = false
      } else {
        this.endOpen = false
      }
    },
    async handlePreview(pointPhoto) {
      if (!pointPhoto.url && !pointPhoto.preview) {
        pointPhoto.preview = await getBase64(pointPhoto.originFileObj)
      }
      this.previewImage = pointPhoto.url || pointPhoto.preview
      this.previewVisible = true
    },
    handleChange({ fileList }) {
      this.fileList = fileList
    },
    handleCancel() {
      this.previewVisible = false
    }
  }
}
</script>

<style scoped>
.photo {
  width: 100%;
}
</style>
